import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import LoginPage from "./components/LoginPage/LoginPage.tsx";
import ChatPageWrapper from "./components/ChatPageWrapper/ChatPageWrapper.tsx";
import LoginForm from "./components/LoginPage/LoginForm/LoginForm.tsx";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute.tsx";
import RegistrationForm from "./components/LoginPage/RegistrationForm/RegistrationForm.tsx";
import ForgotPassForm from "./components/LoginPage/ForgotPassForm/ForgotPassForm.tsx";
import ResetPassForm from "./components/LoginPage/ResetPassForm/ResetPassForm.tsx";
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />}>
          <Route index element={<LoginForm />} />
          <Route path="register" element={<RegistrationForm />} />
          <Route path="forgot-password" element={<ForgotPassForm />} />
          <Route path="reset-password" element={<ResetPassForm />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/chat" element={<ChatPageWrapper />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
