import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { axiosPrivate } from "../../configuration/axiosConfig.ts";

const PrivateRoute = () => {
  const [isAuth, setIsAuth] = useState<boolean | null>(null);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        await axiosPrivate.get("/api/verify-token"); // Backend endpoint to verify the token

        setIsAuth(true);
      } catch (error) {
        setIsAuth(false);
      }
    };

    verifyToken();
  }, []);

  if (isAuth === null) {
    return <div>Loading...</div>;
  }

  return isAuth ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
