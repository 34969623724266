// ChatPageWrapper.tsx
import React from "react";
import ChatPage from "./ChatPage/ChatPage.tsx";
import { UserProvider } from "../ContextProviders/UserContext.tsx";
import { WebSocketProvider } from "../ContextProviders/WebSocketContext.tsx";
import { NotificationProvider } from "../ContextProviders/NotificationContext.tsx";
import NotificationModal from "../Modals/Notification/NotificationModal.tsx";

const ChatPageWrapper = () => {
  return (
    <NotificationProvider>
      <NotificationModal />
      <UserProvider>
        <WebSocketProvider>
          <ChatPage />
        </WebSocketProvider>
      </UserProvider>
    </NotificationProvider>
  );
};

export default ChatPageWrapper;
