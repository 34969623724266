import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ResetPassForm.css";
import { FaEnvelope } from "react-icons/fa";
import useLoginHandlers from "../../../handlers/LoginPageHandlers.tsx";

const ResetPassForm = () => {
  const [email, setEmail] = useState("");
  const { handleForgotPassword, error } = useLoginHandlers();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    handleForgotPassword(email);
  };

  return (
    <div className="wrapper">
      <form onSubmit={handleSubmit}>
        <h1>EngiNearMe</h1>
        <p>Connect. Share. Grow.</p>
        <br></br>
        {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
        {!error && <p>Enter your email address to reset your password.</p>}
        <div className="input-box-email-forgot">
          <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
          <FaEnvelope className="icon" />
        </div>
        <button type="submit">Send Reset Link</button>
        <div className="login-link">
          <p>
            Remembered your password? <Link to="/">Go to Login</Link>
          </p>
        </div>
        <br></br>
      </form>
    </div>
  );
};

export default ResetPassForm;
