import React, { useState } from "react";
import "./UserInfo.css";
import { CiCircleMore } from "react-icons/ci";
import { FaVideo } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { RxAvatar } from "react-icons/rx";
import { useUser } from "../../../../ContextProviders/UserContext.tsx";
import ProfileModal from "../../../../Modals/ProfilePicture/ProfileModal.tsx";
import UserInfoModal from "../../../../Modals/UserInfoModal/UserInfoModal.tsx"; // Import the new modal
import { handleSetDescription } from "../../../../../handlers/ChatPageHandlers.tsx";
import { useNotification } from "../../../../ContextProviders/NotificationContext.tsx";

const UserInfo = () => {
  const { username, userId, profileImage, setProfileImage } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const { showNotification } = useNotification();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openDescriptionModal = () => setIsDescriptionModalOpen(true);
  const closeDescriptionModal = () => setIsDescriptionModalOpen(false);

  const handleUploadSuccess = (imageUrl: string) => {
    setProfileImage(imageUrl); // Set the new profile image
    showNotification("Profile picture was uploaded succesfully!", "success");
  };

  const handleSubmitDescription = async (description: string) => {
    try {
      await handleSetDescription(userId, description);
      // Optionally: Provide feedback to the user or refresh data
      showNotification("Description was added succesfully!", "success");
    } catch (error) {
      showNotification("Failed to update description.", "error");
      console.error("Failed to update description:", error);
    }
  };

  return (
    <div className="userInfo">
      <div className="user-details">
        {profileImage ? (
          <img src={profileImage} alt="Profile" className="avatar-icon" onClick={openModal} />
        ) : (
          <RxAvatar className="avatar-icon" onClick={openModal} />
        )}
        <div className="user-info-username">{username}</div>
      </div>
      <div className="icons">
        <CiCircleMore className="options" onClick={openDescriptionModal} />
        <FaVideo className="options" />
        <FaEdit className="options" />
      </div>
      <ProfileModal isOpen={isModalOpen} onClose={closeModal} onUploadSuccess={handleUploadSuccess} />
      <UserInfoModal isOpen={isDescriptionModalOpen} onClose={closeDescriptionModal} onSubmit={handleSubmitDescription} />
    </div>
  );
};

export default UserInfo;
