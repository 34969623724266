import React, { useState, useEffect, useCallback, useRef } from "react";
import "./SearchSection.css";
import { FaSearch } from "react-icons/fa";
import { IoPersonAdd } from "react-icons/io5";
import FriendRequestsModal from "../../../../Modals/FriendRequest/FriendRequestsModal.tsx";
import { handleDeleteFriendRequest, handleAcceptFriendRequest } from "../../../../../handlers/ChatPageHandlers.tsx";
import { useUser } from "../../../../ContextProviders/UserContext.tsx";
import { debounce } from "../../../../../utils/debounce.js";
import { useNotification } from "../../../../ContextProviders/NotificationContext.tsx";
import { useWebSocket } from "../../../../ContextProviders/WebSocketContext.tsx";

interface FriendRequestRequesterInfo {
  userId: number;
  username: string;
  email: string;
  description: string | null;
  profileImage: string | undefined;
}

const SearchSection = ({ onSearchChange, setSearchDebounceLoading }) => {
  const { userId, friendRequestRequesters, setFriendRequestRequesters } = useUser(); // Use the context here
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // Local state for search query
  const { showNotification } = useNotification();
  const { socket } = useWebSocket();
  const socketRef = useRef<WebSocket | null>(socket);

  // Debounced version of the onSearchChange function
  const debouncedSearch = useCallback(
    debounce(async query => {
      setSearchDebounceLoading(true); // Set loading to true before starting the search

      try {
        await onSearchChange(query); // Call the onSearchChange function
      } catch (error) {
        console.error("Error in onSearchChange:", error);
      } finally {
        setSearchDebounceLoading(false); // Ensure loading is set to false after the search completes
      }
    }, 560),
    [onSearchChange]
  );

  useEffect(() => {
    // Call debounced function whenever searchQuery changes
    debouncedSearch(searchQuery);
  }, [searchQuery, debouncedSearch]);

  const handleInputChange = event => {
    setSearchQuery(event.target.value); // Update local search query
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleAccept = async (id: number) => {
    try {
      const result = await handleAcceptFriendRequest(id, userId);
      if (result) {
        setFriendRequestRequesters(prev => prev.filter(request => request.userId !== id));
        showNotification("Friend request was accepted!", "success");
      } else {
        console.error("Failed to add friend request");
      }
    } catch (error) {
      console.error("Error adding friend request:", error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      const result = await handleDeleteFriendRequest(id);
      if (result) {
        setFriendRequestRequesters(prev => prev.filter(request => request.userId !== id));
        showNotification("Friend request was deleted!", "success");
      } else {
        showNotification("Failed to delete friend request.", "error");
        console.error("Failed to delete friend request");
      }
    } catch (error) {
      console.error("Error deleting friend request:", error);
    }
  };

  const handleBlock = (id: number) => {
    setFriendRequestRequesters(prev => prev.filter(request => request.userId !== id));
  };

  useEffect(() => {
    socketRef.current = socket; // Update the ref whenever socket changes

    const handleMessage = (event: MessageEvent) => {
      try {
        const receivedData: FriendRequestRequesterInfo = JSON.parse(event.data); // Parse the incoming data

        if (receivedData) {
          // Directly update the state with new friend request data
          setFriendRequestRequesters(prevRequests => {
            // Check if the request already exists
            const existingRequest = prevRequests.find(request => request.userId === receivedData.userId);

            if (existingRequest) {
              // If the request already exists, return the array unchanged
              return prevRequests;
            } else {
              // If it's a new request, add it to the top of the list
              return [receivedData, ...prevRequests];
            }
          });

          // Optionally, display a notification
          showNotification("New friend request received!", "warning");
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    if (socket) {
      socket.onmessage = handleMessage; // Assign the message handler
    }

    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      if (socket) {
        socket.onmessage = null;
      }
    };
  }, [socket]); // Depend on socket

  return (
    <div className="search">
      <div className="searchBar">
        <FaSearch className="searchImg" />
        <input type="text" onChange={handleInputChange} placeholder="Search" value={searchQuery} />
      </div>
      <div className="addIcon">
        <IoPersonAdd className="plusImg" onClick={handleOpenModal} />
        <div className="friend-number">{friendRequestRequesters?.length > 0 && <div className="badge">{friendRequestRequesters?.length}</div>}</div>
        <FriendRequestsModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          friendRequestersInfo={friendRequestRequesters}
          onAccept={handleAccept}
          onDelete={handleDelete}
          onBlock={handleBlock}
        />
      </div>
    </div>
  );
};

export default SearchSection;
