import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./RegistrationForm.css"; // Make sure to create appropriate styles
import { FaUser, FaEnvelope, FaLock, FaEyeSlash, FaEye } from "react-icons/fa";
import useLoginHandlers from "../../../handlers/LoginPageHandlers.tsx";
import SuccessModal from "../../Modals/Registration/SuccessModal.tsx"; // Import your modal

const RegistrationForm = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Track password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Track password visibility
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { handleRegister, error, showSuccessModal } = useLoginHandlers();

  const handleSubmit = e => {
    e.preventDefault();
    handleRegister(username, email, password, confirmPassword);
  };

  // Toggle the visibility of the password
  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prevState => !prevState);
  };

  return (
    <div className="wrapper">
      <form onSubmit={handleSubmit}>
        <h1>EngiNearMe</h1>
        <p>Connect. Share. Grow.</p>
        <br></br>

        {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
        {!error && <p>Create a new account.</p>}
        <div className="input-box-email-reg">
          <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
          <FaEnvelope className="icon" />
        </div>
        <div className="input-box-username">
          <input type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="Username" required />
          <FaUser className="icon" />
        </div>
        <div className="input-box-password">
          <input
            type={showPassword ? "text" : "password"} // Conditionally set type to show/hide password
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="New password"
            required
            autoComplete="off"
          />
          {password.length > 0 ? (
            <span onClick={togglePasswordVisibility} className="reg-password-toggle-icon">
              {showPassword ? <FaEyeSlash className="icon" style={{ cursor: "pointer" }} /> : <FaEye className="icon" style={{ cursor: "pointer" }} />}{" "}
            </span>
          ) : (
            <FaLock className="icon" /> /* Show lock icon when there's no input */
          )}
        </div>
        <div className="input-box-confirm-password">
          <input
            type={showConfirmPassword ? "text" : "password"} // Conditionally set type to show/hide password
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            placeholder="Confirm password"
            required
            autoComplete="off"
          />
          {confirmPassword.length > 0 ? (
            <span onClick={toggleConfirmPasswordVisibility} className="reg-password-toggle-icon">
              {showConfirmPassword ? <FaEyeSlash className="icon" style={{ cursor: "pointer" }} /> : <FaEye className="icon" style={{ cursor: "pointer" }} />}{" "}
            </span>
          ) : (
            <FaLock className="icon" /> /* Show lock icon when there's no input */
          )}
        </div>
        <button type="submit">Register</button>
        <div className="login-link">
          <p>
            Already have an account? <Link to="/">Go to Login</Link>
          </p>
        </div>
      </form>
      {showSuccessModal && (
        <SuccessModal
          message="Registration successful!"
          onClose={() => {
            /* No-op */
          }}
        />
      )}
    </div>
  );
};

export default RegistrationForm;
