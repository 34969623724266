import React, { useEffect } from "react";
import "./SuccessModal.css"; // Ensure you create appropriate styles

const SuccessModal = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 2000); // Hide modal after 2 seconds

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [onClose]);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="success-message">{message}</div>
      </div>
    </div>
  );
};

export default SuccessModal;
