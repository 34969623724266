import React, { useState } from "react";
import UserInfo from "./UserInfo/UserInfo.tsx";
import ChatList from "./ChatList/ChatList.tsx";
import SearchSection from "./SearchSection/SearchSection.tsx";

import "./List.css";

const List = ({ onCurrentConversationIDChange, onCurrentContactIDChange, onCurrentContactUsernameChange, message, onSelectConversation }) => {
  // State to track if the user has typed anything in the search bar
  const [isSearching, setIsSearching] = useState(false);
  const [searchDebounceLoading, setSearchDebounceLoading] = useState(false);

  // State to track the search query
  const [searchQuery, setSearchQuery] = useState("");

  const handleCurrentConversationIDChange = (conversationID: number) => {
    onCurrentConversationIDChange(conversationID);
  };

  const handleCurrentContactIDChange = (contactID: number) => {
    onCurrentContactIDChange(contactID);
  };

  const handleCurrentContactUsernameChange = (contactUsername: string) => {
    onCurrentContactUsernameChange(contactUsername);
  };

  const clearSearchQuery = () => {
    setSearchQuery(""); // This will clear the search query, but the change won't be reflected immediately in console.log
    setIsSearching(false); // Setting the isSearching to false
  };

  // Handler for search input change
  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
    setIsSearching(query.length > 0);
  };

  const contactClick = () => {
    onSelectConversation();
  };

  return (
    <div className="list">
      <UserInfo></UserInfo>
      <SearchSection onSearchChange={handleSearchChange} setSearchDebounceLoading={setSearchDebounceLoading} />
      <ChatList
        message={message}
        isSearching={isSearching}
        searchQuery={searchQuery}
        searchDebounceLoading={searchDebounceLoading}
        onCurrentConversationChange={handleCurrentConversationIDChange}
        onCurrentContactChange={handleCurrentContactIDChange}
        onCurrentContactUsernameChange={handleCurrentContactUsernameChange}
        onContactClick={clearSearchQuery}
        onContactClickFromContactList={contactClick}
      />
    </div>
  );
};

export default List;
