import React, { useState, useEffect } from "react";
import "./ChatList.css";

import { handlePersonSearch, handleGetConversationsInfo, handleAddFriend, handleDeleteFriendRequest } from "../../../../../handlers/ChatPageHandlers.tsx";
import { useUser } from "../../../../ContextProviders/UserContext.tsx"; // Adjust import path
import SuccessModal from "../../../../Modals/Registration/SuccessModal.tsx";
import { useNotification } from "../../../../ContextProviders/NotificationContext.tsx";

interface User {
  id: number;
  username: string;
  profile_picture: string;
  is_contact: boolean;
}

interface ConversationInfo {
  contact_id: number;
  contact_username: string;
  profile_picture: string;
  user_description: string;
  sender_id: number;
  attachment_id: number;
  last_message: string;
  sent_at: string;
  conversation_id: number;
}

interface Message {
  type: string;
  id: number;
  sender_id: number;
  contact_id: number | null;
  conversation_id: number | null;
  content: string;
  attachment_id?: number; // Optional attachment ID
  image_url?: string; // URL of associated image
  image_data?: string; // Base64 encoded image data (optional)
  sent_at: string;
}

const ChatList = ({
  message,
  isSearching,
  searchQuery,
  searchDebounceLoading,
  onCurrentConversationChange,
  onCurrentContactChange,
  onCurrentContactUsernameChange,
  onContactClick,
  onContactClickFromContactList,
}: {
  message: Message;
  isSearching: boolean;
  searchQuery: string;
  searchDebounceLoading: boolean;
  onCurrentConversationChange: (conversationID: number) => void;
  onCurrentContactChange: (contactID: number) => void;
  onCurrentContactUsernameChange: (contactUsername: string) => void;
  onContactClick: () => void;
  onContactClickFromContactList: () => void;
}) => {
  const { userId, contacts, friendRequestRecipients, setFriendRequestRecipients } = useUser(); // Use the context here
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [conversationsInfo, setConversationsInfo] = useState<ConversationInfo[]>([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const [showAddSuccessModal, setShowAddSuccessModal] = useState(false); // Modal state
  const [chatListView, setChatListView] = useState("all"); // Add loading state
  const notificationSound = "/sounds/notification.mp3"; // This assumes the file is directly in the `public` folder
  const { showNotification } = useNotification();

  // const filteredConversations = conversationsInfo?.filter(convo => convo.contact_username.toLowerCase().includes(searchQuery.toLowerCase()));

  const filteredConversations = conversationsInfo
    ?.filter(convo => convo.contact_username.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => a.contact_username.localeCompare(b.contact_username));

  const filteredContacts = contacts
    ?.filter(convo => convo.username.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => a.username.localeCompare(b.username));

  const filteredFriendRequests = friendRequestRecipients
    ?.filter(convo => convo.username.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => a.username.localeCompare(b.username));

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        setLoading(true); // Start loading

        const data = await handlePersonSearch(searchQuery, userId);
        setSearchResults(data);
      } catch (err) {
        // Handle error (you can display an error message here)
      } finally {
        setLoading(false); // Stop loading
      }
    };

    if (searchQuery) {
      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery, userId]);

  useEffect(() => {
    if (isSearching) {
      // When isSearching changes from false to true, set chatListView to "all"
      setChatListView("all");
    } else {
      // When isSearching changes from true to false, set chatListView to "conversations"
      setChatListView("conversations");
    }
  }, [isSearching, setChatListView]);

  useEffect(() => {
    const fetchConversationsInfo = async () => {
      try {
        setLoading(true); // Start loading
        // await delay(10000); // 1 second delay for simulation

        const data = await handleGetConversationsInfo(userId);
        setConversationsInfo(data);
        // showNotification("Conversations successfully fetched!", "success");
      } catch (err) {
        // Handle error (you can display an error message here)
      } finally {
        setLoading(false); // Stop loading
      }
    };

    if (userId) {
      fetchConversationsInfo();
    } else {
      setConversationsInfo([]);
    }
  }, [userId]);

  const handleDelete = async (id: number) => {
    try {
      const result = await handleDeleteFriendRequest(id);
      if (result) {
        setFriendRequestRecipients(prev => prev.filter(request => request.userId !== id));
      } else {
        showNotification("Failed to delete friend request.", "error"); // Show modal on error
        // console.error("Failed to delete friend request");
      }
    } catch (error) {
      console.error("Error deleting friend request:", error);
    }
  };

  useEffect(() => {
    if (!message) return; // Make sure incomingMessage is valid before processing

    const conversationIndex = conversationsInfo.findIndex(conversation => conversation.conversation_id === message.conversation_id);

    if (conversationIndex === -1) return;

    const updatedConversations = conversationsInfo.map(conversation => {
      if (conversation.conversation_id === message.conversation_id) {
        if (message.sender_id !== userId) {
          const audio = new Audio(notificationSound);
          audio.play();
        }
        // Update the conversation with the incoming message data
        return {
          ...conversation,
          sender_id: message.sender_id,
          last_message: message.content, // Assuming `incommingMessage.message` contains the text
          sent_at: message.sent_at, // Assuming `incommingMessage.sent_at` is the time of the message
        };
      }
      return conversation;
    });

    // Find the conversation that was updated and move it to the top
    const updatedConversation = updatedConversations.find(conversation => conversation.conversation_id === message.conversation_id);

    if (updatedConversation) {
      // Remove the updated conversation from its current position and add it to the front of the array
      const reorderedConversations = [
        updatedConversation,
        ...updatedConversations.filter(conversation => conversation.conversation_id !== message.conversation_id),
      ];

      // Update the state with the reordered conversations
      setConversationsInfo(reorderedConversations);
    }
  }, [message]); // Trigger the effect whenever incommingMessage changes

  const updateCurrentConversation = conversationID => {
    onCurrentConversationChange(conversationID);
  };

  const updateCurrentContact = contactID => {
    onCurrentContactChange(contactID);
  };

  const updateCurrentContactUsername = contactUsername => {
    onCurrentContactUsernameChange(contactUsername);
  };

  const lastWordsOfLastMessage = text => {
    if (text.length >= 60) {
      return `${text.slice(0, 60)}...`;
    } else {
      return text;
    }
  };

  const formatDate = isoString => {
    const date = new Date(isoString);

    // Define the options for date formatting
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: "numeric", // 'numeric' or '2-digit'
      month: "2-digit", // 'numeric' or '2-digit'
      day: "2-digit", // 'numeric' or '2-digit'
    };

    // Define the options for time formatting
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: "2-digit", // 'numeric' or '2-digit'
      minute: "2-digit", // 'numeric' or '2-digit'
    };
    // Format date and time separately
    const formattedDate = date.toLocaleDateString("en-US", dateOptions);
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

    // Combine formatted date and time
    return `${formattedDate}, ${formattedTime}`;
  };

  // Function to fetch and filter chats or contacts based on search query
  const getFilteredData = () => {
    if (isSearching) {
      // Fetch and filter contacts and available users based on searchQuery
      // This is a placeholder, replace with actual data-fetching logic
      if (chatListView === "all") {
        return (
          <div className="chat-list-container">
            <div className="chat-list-categories">
              <button className="all-button active" onClick={() => setChatListView("all")}>
                All
              </button>
              <button className="conversations-button" onClick={() => setChatListView("conversations")}>
                Conversations
              </button>
              <button className="contacts-button" onClick={() => setChatListView("contacts")}>
                Contacts
              </button>
              <button className="friend-requests-button" onClick={() => setChatListView("friend-requests")}>
                Friend Requests
              </button>
            </div>
            <div className="chat-list">
              <ul>
                {searchResults?.map(user =>
                  user.is_contact ? (
                    <li
                      className="searchResult"
                      key={user.id}
                      onClick={() => {
                        const conversation = conversationsInfo?.find(convo => convo.contact_id === user.id);
                        const conversationID = conversation ? conversation.conversation_id : 0;
                        updateCurrentConversation(conversationID);
                        updateCurrentContact(user.id);
                        updateCurrentContactUsername(user.username);
                        onContactClick();
                        onContactClickFromContactList();
                      }}
                    >
                      <img src={user.profile_picture ? user.profile_picture : "./boy.png"} alt="" />
                      <div className="contactUsername">{user.username}</div>
                    </li>
                  ) : (
                    <li className="searchResult" key={user.id}>
                      <img src={user.profile_picture ? user.profile_picture : "./boy.png"} alt="" />
                      <div className="contactUsername">{user.username}</div>
                      <button
                        className="addButton"
                        onClick={async () => {
                          try {
                            await handleAddFriend(userId, user.id);
                            showNotification("Friend request sent!", "success"); // Show modal on success
                          } catch (error) {
                            // Handle errors if necessary
                            showNotification("Failed to add friend.", "error"); // Show modal on success

                            // console.error("Failed to add friend:", error);
                          } finally {
                          }
                        }}
                      >
                        Add
                      </button>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        );
      } else if (chatListView === "conversations") {
        return (
          <div className="chat-list-container">
            <div className="chat-list-categories">
              <button className="all-button" onClick={() => setChatListView("all")}>
                All
              </button>
              <button className="conversations-button active" onClick={() => setChatListView("conversations")}>
                Conversations
              </button>
              <button className="contacts-button" onClick={() => setChatListView("contacts")}>
                Contacts
              </button>
              <button className="friend-requests-button" onClick={() => setChatListView("friend-requests")}>
                Friend Requests
              </button>
            </div>
            <div className="chat-list">
              <ul>
                {filteredConversations?.map(conv => (
                  <li
                    className="searchResult"
                    key={conv.contact_id}
                    onClick={() => {
                      const conversation = conversationsInfo?.find(convo => convo.contact_id === conv.contact_id);
                      const conversationID = conversation ? conversation.conversation_id : 0;
                      updateCurrentConversation(conversationID);
                      updateCurrentContact(conversation?.contact_id);
                      updateCurrentContactUsername(conversation?.contact_username);
                      onContactClick();
                      onContactClickFromContactList();
                    }}
                  >
                    <img src={conv.profile_picture ? conv.profile_picture : "./boy.png"} alt="" />
                    <div className="contactUsername">{conv.contact_username}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      } else if (chatListView === "contacts") {
        return (
          <div className="chat-list-container">
            <div className="chat-list-categories">
              <button className="all-button" onClick={() => setChatListView("all")}>
                All
              </button>
              <button className="conversations-button" onClick={() => setChatListView("conversations")}>
                Conversations
              </button>
              <button className="contacts-button active" onClick={() => setChatListView("contacts")}>
                Contacts
              </button>
              <button className="friend-requests-button" onClick={() => setChatListView("friend-requests")}>
                Friend Requests
              </button>
            </div>
            <div className="chat-list">
              <ul>
                {filteredContacts?.map(contact => (
                  <li
                    className="searchResult"
                    key={contact.userId}
                    onClick={() => {
                      const conversation = conversationsInfo?.find(convo => convo.contact_id === contact.userId);
                      const conversationID = conversation ? conversation.conversation_id : 0;
                      updateCurrentConversation(conversationID);
                      updateCurrentContact(conversation?.contact_id);
                      updateCurrentContactUsername(conversation?.contact_username);
                      onContactClick();
                      onContactClickFromContactList();
                    }}
                  >
                    <img src={contact.profileImage ? contact.profileImage : "./boy.png"} alt="" />
                    <div className="contactUsername">{contact.username}</div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      } else if (chatListView === "friend-requests") {
        return (
          <div className="chat-list-container">
            <div className="chat-list-categories">
              <button className="all-button" onClick={() => setChatListView("all")}>
                All
              </button>
              <button className="conversations-button" onClick={() => setChatListView("conversations")}>
                Conversations
              </button>
              <button className="contacts-button" onClick={() => setChatListView("contacts")}>
                Contacts
              </button>
              <button className="friend-requests-button active" onClick={() => setChatListView("friend-requests")}>
                Friend Requests
              </button>
            </div>
            <div className="chat-list">
              <ul>
                {filteredFriendRequests?.map(friendRequest => (
                  <li className="searchResult" key={friendRequest.userId}>
                    <img src={friendRequest.profileImage ? friendRequest.profileImage : "./boy.png"} alt="" />
                    <div className="contactUsername">{friendRequest.username}</div>
                    <button className="cancelButton" onClick={() => handleDelete(friendRequest.userId)}>
                      Cancel Request
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      } else {
        return (
          <div className="chat-list-container">
            <div className="chat-list-categories">
              <button className="all-button active" onClick={() => setChatListView("all")}>
                All
              </button>
              <button className="conversations-button" onClick={() => setChatListView("conversations")}>
                Conversations
              </button>
              <button className="contacts-button" onClick={() => setChatListView("contacts")}>
                Contacts
              </button>
              <button className="friend-requests-button" onClick={() => setChatListView("friend-requests")}>
                Friend Requests
              </button>
            </div>
            <div className="chat-list">
              <ul>
                {searchResults?.map(user =>
                  user.is_contact ? (
                    <li
                      className="searchResult"
                      key={user.id}
                      onClick={() => {
                        const conversation = conversationsInfo?.find(convo => convo.contact_id === user.id);
                        const conversationID = conversation ? conversation.conversation_id : 0;
                        updateCurrentConversation(conversationID);
                        updateCurrentContact(user.id);
                        updateCurrentContactUsername(user.username);
                        onContactClick();
                        onContactClickFromContactList();
                      }}
                    >
                      <img src={user.profile_picture ? user.profile_picture : "./boy.png"} alt="" />
                      <div className="contactUsername">{user.username}</div>
                    </li>
                  ) : (
                    <li className="searchResult" key={user.id}>
                      <img src={user.profile_picture ? user.profile_picture : "./boy.png"} alt="" />
                      <div className="contactUsername">{user.username}</div>
                      <button
                        className="addButton"
                        onClick={async () => {
                          try {
                            await handleAddFriend(userId, user.id);
                            showNotification("Friend request sent!", "success"); // Show modal on success
                          } catch (error) {
                            // Handle errors if necessary
                            showNotification("Failed to add friend.", "error"); // Show modal on success

                            // console.error("Failed to add friend:", error);
                          }
                        }}
                      >
                        Add
                      </button>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        );
      }
    } else {
      if (chatListView === "conversations") {
        // Handle conversations
        return (
          <div className="chat-list-container">
            <div className="chat-list-categories">
              <button className="conversations-button active" onClick={() => setChatListView("conversations")}>
                Conversations
              </button>
              <button className="contacts-button" onClick={() => setChatListView("contacts")}>
                Contacts
              </button>
              <button className="friend-requests-button" onClick={() => setChatListView("friend-requests")}>
                Friend Requests
              </button>
            </div>
            <div className="chat-list">
              <ul>
                {Array.isArray(conversationsInfo) && conversationsInfo.length > 0 ? (
                  conversationsInfo
                    .sort((a, b) => new Date(b.sent_at).getTime() - new Date(a.sent_at).getTime()) // Sort from latest to oldest
                    .map(conversationInfo => (
                      <li key={conversationInfo.contact_id}>
                        <div
                          className="contactResult"
                          onClick={() => {
                            if (conversationInfo && conversationInfo.conversation_id && conversationInfo.contact_id) {
                              updateCurrentConversation(conversationInfo.conversation_id);
                              updateCurrentContact(conversationInfo.contact_id);
                              updateCurrentContactUsername(conversationInfo.contact_username);
                              onContactClickFromContactList();
                            } else {
                              console.error("Invalid conversationInfo:", conversationInfo);
                            }
                          }}
                        >
                          <img
                            src={conversationInfo.profile_picture ? conversationInfo.profile_picture : "./boy.png"}
                            alt={conversationInfo.contact_username}
                          />
                          <div className="conversationInfo">
                            <div className="contactUsername">{conversationInfo.contact_username}</div>
                            <div className="lastMessage">
                              {userId === conversationInfo.sender_id ? (
                                <>
                                  Me:{" "}
                                  {conversationInfo.attachment_id !== null && conversationInfo.attachment_id !== 0
                                    ? "Sent an image."
                                    : lastWordsOfLastMessage(conversationInfo.last_message)}
                                </>
                              ) : (
                                <>
                                  {conversationInfo.attachment_id !== null && conversationInfo.attachment_id !== 0
                                    ? "Sent an image."
                                    : lastWordsOfLastMessage(conversationInfo.last_message)}
                                </>
                              )}

                              <div style={{ fontSize: "0.8em", color: "#888" }}>{formatDate(conversationInfo.sent_at)}</div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))
                ) : (
                  <div style={{ fontSize: "16px", padding: "5px" }}>No conversations</div>
                )}
              </ul>
            </div>
          </div>
        );
      } else if (chatListView === "contacts") {
        // Handle contacts
        return (
          <div className="chat-list-container">
            <div className="chat-list-categories">
              <button className="conversations-button" onClick={() => setChatListView("conversations")}>
                Conversations
              </button>
              <button className="contacts-button active" onClick={() => setChatListView("contacts")}>
                Contacts
              </button>
              <button className="friend-requests-button" onClick={() => setChatListView("friend-requests")}>
                Friend Requests
              </button>
            </div>
            <div className="chat-list">
              <ul>
                {Array.isArray(contacts) && contacts.length > 0 ? (
                  contacts.map(contact => (
                    <li key={contact.userId}>
                      <div
                        className="contactResult"
                        onClick={() => {
                          if (contact && contact.userId) {
                            const conversation = conversationsInfo?.find(convo => convo.contact_id === contact.userId);
                            const conversationID = conversation ? conversation.conversation_id : 0;
                            updateCurrentConversation(conversationID);
                            updateCurrentContact(contact.userId);
                            updateCurrentContactUsername(contact.username);
                            onContactClick();
                            onContactClickFromContactList();
                          } else {
                            console.error("Invalid contact:", contact);
                          }
                        }}
                      >
                        <img src={contact.profileImage ? contact.profileImage : "./boy.png"} alt={contact.username} />
                        <div className="contactInfo">
                          <div className="contactUsername">{contact.username}</div>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <div style={{ fontSize: "16px", padding: "5px" }}>No contacts</div>
                )}
              </ul>
            </div>
          </div>
        );
      } else if (chatListView === "friend-requests") {
        return (
          <div className="chat-list-container">
            <div className="chat-list-categories">
              <button className="conversations-button" onClick={() => setChatListView("conversations")}>
                Conversations
              </button>
              <button className="contacts-button" onClick={() => setChatListView("contacts")}>
                Contacts
              </button>
              <button className="friend-requests-button active" onClick={() => setChatListView("friend-requests")}>
                Friend Requests
              </button>
            </div>
            <div className="chat-list">
              <ul>
                {Array.isArray(friendRequestRecipients) && friendRequestRecipients.length > 0 ? (
                  friendRequestRecipients.map(recipient => (
                    <li key={recipient.userId}>
                      <div className="contactResult">
                        <img src={recipient.profileImage ? recipient.profileImage : "./boy.png"} alt={recipient.username} />
                        <div className="contactUsername">{recipient.username}</div>
                        <button className="cancelButton" onClick={() => handleDelete(recipient.userId)}>
                          Cancel Request
                        </button>
                      </div>
                    </li>
                  ))
                ) : (
                  <div style={{ fontSize: "16px", padding: "5px" }}>No friend requests</div>
                )}
              </ul>
            </div>
          </div>
        );
      } else {
        // Fetch and display user's chats
        // This is a placeholder, replace with actual data-fetching logic
        return (
          <div className="chat-list-container">
            <div className="chat-list-categories">
              <button className="conversations-button active" onClick={() => setChatListView("conversations")}>
                Conversations
              </button>
              <button className="contacts-button" onClick={() => setChatListView("contacts")}>
                Contacts
              </button>
              <button className="friend-requests-button" onClick={() => setChatListView("friend-requests")}>
                Friend Requests
              </button>
            </div>
            <div className="chat-list">
              <ul>
                {Array.isArray(conversationsInfo) &&
                  conversationsInfo
                    .sort((a, b) => new Date(b.sent_at).getTime() - new Date(a.sent_at).getTime()) // Sort from latest to oldest
                    .map(conversationInfo => (
                      <li key={conversationInfo.contact_id}>
                        <div
                          className="contactResult"
                          onClick={() => {
                            // Ensure conversationInfo and its properties are defined
                            if (conversationInfo && conversationInfo.conversation_id && conversationInfo.contact_id) {
                              updateCurrentConversation(conversationInfo.conversation_id);
                              updateCurrentContact(conversationInfo.contact_id);
                              updateCurrentContactUsername(conversationInfo.contact_username);
                              onContactClickFromContactList();
                            } else {
                              console.error("Invalid conversationInfo:", conversationInfo);
                            }
                          }}
                        >
                          <img src={conversationInfo.profile_picture ? conversationInfo.profile_picture : "./boy.png"} alt="" />
                          <div className="conversationInfo">
                            <div className="contactUsername">{conversationInfo.contact_username}</div>
                            <div className="lastMessage">
                              {userId === conversationInfo.sender_id ? (
                                <>
                                  Me:
                                  {conversationInfo.attachment_id !== null && conversationInfo.attachment_id !== 0
                                    ? "Sent an image."
                                    : lastWordsOfLastMessage(conversationInfo.last_message)}
                                </>
                              ) : (
                                <>
                                  {conversationInfo.attachment_id !== null && conversationInfo.attachment_id !== 0
                                    ? "Sent an image."
                                    : lastWordsOfLastMessage(conversationInfo.last_message)}
                                </>
                              )}

                              <div style={{ fontSize: "0.8em", color: "#888" }}>{formatDate(conversationInfo.sent_at)}</div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
              </ul>
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div className="chat-list">
      {showAddSuccessModal && (
        <SuccessModal
          message="Friend Request Sent!"
          onClose={() => setShowAddSuccessModal(false)} // Close modal on button click
        />
      )}
      {loading || searchDebounceLoading ? (
        <div className="chat-list-loading-indicator">
          <div className="chat-list-spinner"></div>
        </div>
      ) : (
        getFilteredData()
      )}
    </div>
  );
};

export default ChatList;
