import React, { createContext, useState, useEffect, ReactNode } from "react";
import { axiosPrivate } from "../../configuration/axiosConfig.ts";

interface ContactInfo {
  userId: number;
  username: string;
  email: string;
  description: string | null;
  profileImage: string | null;
}

interface FriendRequestRequesterInfo {
  userId: number;
  username: string;
  email: string;
  description: string | null;
  profileImage: string | undefined;
}

interface FriendRequestRecipientInfo {
  userId: number;
  username: string;
  email: string;
  description: string | null;
  profileImage: string | undefined;
}

interface UserContextType {
  userId: number;
  username: string;
  email: string;
  description: string | null;
  profileImage: string | null;
  contacts: ContactInfo[];
  friendRequestRequesters: FriendRequestRequesterInfo[];
  friendRequestRecipients: FriendRequestRecipientInfo[];
  setUserId: React.Dispatch<React.SetStateAction<number>>;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<string | null>>;
  setProfileImage: React.Dispatch<React.SetStateAction<string | null>>;
  setContacts: React.Dispatch<React.SetStateAction<ContactInfo[]>>;
  setFriendRequestRequesters: React.Dispatch<React.SetStateAction<FriendRequestRequesterInfo[]>>;
  setFriendRequestRecipients: React.Dispatch<React.SetStateAction<FriendRequestRecipientInfo[]>>;
}

const defaultContextValue: UserContextType = {
  userId: 0,
  username: "",
  email: "",
  description: null,
  profileImage: null,
  contacts: [],
  friendRequestRequesters: [],
  friendRequestRecipients: [],
  setUserId: () => {},
  setUsername: () => {},
  setEmail: () => {},
  setDescription: () => {},
  setProfileImage: () => {},
  setContacts: () => {},
  setFriendRequestRequesters: () => {},
  setFriendRequestRecipients: () => {},
};

const UserContext = createContext<UserContextType>(defaultContextValue);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [userId, setUserId] = useState<number>(0);
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [description, setDescription] = useState<string | null>(null);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [contacts, setContacts] = useState<ContactInfo[]>([]);
  const [friendRequestRequesters, setFriendRequestRequesters] = useState<FriendRequestRequesterInfo[]>([]);
  const [friendRequestRecipients, setFriendRequestRecipients] = useState<FriendRequestRecipientInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosPrivate.get("/api/user");
        const data = response.data;

        setUserId(data.user.userId);
        setUsername(data.user.username);
        setEmail(data.user.email);
        setDescription(data.user.description);
        setProfileImage(data.user.profileImage);

        setContacts(data.contacts);
        setFriendRequestRequesters(data.friendRequestRequesters);
        setFriendRequestRecipients(data.friendRequestRecipients);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const value = {
    userId,
    username,
    email,
    description,
    profileImage,
    contacts,
    friendRequestRequesters,
    friendRequestRecipients,
    setUserId,
    setUsername,
    setEmail,
    setDescription,
    setProfileImage,
    setContacts,
    setFriendRequestRequesters,
    setFriendRequestRecipients,
  };

  return <UserContext.Provider value={value}>{!loading && children}</UserContext.Provider>;
};

export const useUser = () => React.useContext(UserContext);
