import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaUser, FaLock, FaEye, FaEyeSlash } from "react-icons/fa"; // Importing Eye icons
import useLoginHandlers from "../../../handlers/LoginPageHandlers.tsx";
import "./LoginForm.css";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false); // Track Remember Me checkbox
  const [showPassword, setShowPassword] = useState(false); // Track password visibility
  const { handleLogin, error } = useLoginHandlers();

  const handleSubmit = e => {
    e.preventDefault();
    localStorage.setItem("rememberMe", rememberMe.toString()); // Convert to string before saving
    localStorage.setItem("currentView", "list"); // Save current view
    handleLogin(username, password);
  };

  // Toggle the visibility of the password
  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  return (
    <div className="wrapper">
      <form onSubmit={handleSubmit}>
        <h1>EngiNearMe</h1>
        <p>Connect. Share. Grow.</p>
        <br></br>
        {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
        {!error && <div className="empty-line"></div>}

        {/* Username Input */}
        <div className="input-box-username">
          <input type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="Username" required />
          <FaUser className="icon" />
        </div>

        {/* Password Input */}
        <div className="input-box-password-login">
          <input
            type={showPassword ? "text" : "password"} // Conditionally set type to show/hide password
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
            required
            autoComplete="off"
          />
          {/* Toggle between Lock and Eye/EyeSlash based on password field and visibility */}
          {password.length > 0 ? (
            <span onClick={togglePasswordVisibility} className="password-toggle-icon">
              {showPassword ? <FaEyeSlash className="icon" style={{ cursor: "pointer" }} /> : <FaEye className="icon" style={{ cursor: "pointer" }} />}{" "}
            </span>
          ) : (
            <FaLock className="icon" /> /* Show lock icon when there's no input */
          )}
        </div>

        {/* Remember Me and Forgot Password */}
        <div className="remember-forgot">
          <label>
            <input type="checkbox" className="checkbox-remember" checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} />
            Remember Me
          </label>
          <Link to="/forgot-password">Forgot password?</Link>
        </div>

        {/* Submit Button */}
        <button type="submit">Login</button>

        {/* Register Link */}
        <div className="register-link">
          <p>
            Don't have an account? <Link to="/register">Register</Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
