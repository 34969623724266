import axios from "axios";

const axiosPrivate = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://enginearme.xyz" // Production backend URL
      : "http://localhost:8080", // Development backend URL (HTTP for local dev)
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // Ensures cookies are included with requests
});

export { axiosPrivate };
