import { axiosPrivate } from "../configuration/axiosConfig.ts";

export const handlePersonSearch = async (searchQuery: string, userID: number) => {
  try {
    // Perform a GET request with query parameters
    const response = await axiosPrivate.get("/api/search-person", {
      params: {
        searchQuery, // Pass searchQuery as query parameter
        userID, // Pass userID as query parameter
      },
    });
    if (response.status === 200) {
      return response.data; // Return the data from the response
    }
  } catch (err) {
    // Handle the error and provide feedback
    console.error("Error fetching search results:", err);
    throw new Error("Fetch from server failed."); // Re-throw the error or handle it as needed
  }
};

export const handleSetDescription = async (userId: number, description: string) => {
  try {
    // Pass searchQuery as a query parameter
    const response = await axiosPrivate.post("/api/set-description", {
      user_id: userId,
      description: description,
    });
    if (response.status === 200) {
      return response.data; // Return the data from the response
    }
  } catch (err) {
    // Handle the error and provide feedback
    console.error("Error fetching search results:", err);
    throw new Error("Fetch from server failed."); // Re-throw the error or handle it as needed
  }
};

export const handleGetDescription = async (userId: number) => {
  try {
    // Pass searchQuery as a query parameter
    const response = await axiosPrivate.get("/api/get-description", {
      params: { u: userId },
    });
    if (response.status === 200) {
      return response.data; // Return the data from the response
    }
  } catch (err) {
    // Handle the error and provide feedback
    console.error("Error fetching search results:", err);
    throw new Error("Fetch from server failed."); // Re-throw the error or handle it as needed
  }
};

export const handleGetConversationsInfo = async (userId: number) => {
  try {
    // Pass searchQuery as a query parameter
    const response = await axiosPrivate.get("/api/conversations-info", {
      params: { u: userId }, // Use 'params' to correctly format query parameters
    });
    if (response.status === 200) {
      return response.data; // Return the data from the response
    }
  } catch (err) {
    // Handle the error and provide feedback
    console.error("Error fetching search results:", err);
    throw new Error("Fetch from server failed."); // Re-throw the error or handle it as needed
  }
};

export const handleAddFriend = async (requester_user_id: number, recipient_user_id: number) => {
  try {
    const response = await axiosPrivate.post("/api/add-friend", { requester_user_id: requester_user_id, recipient_user_id: recipient_user_id });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error adding contact:", error);
  }
};

export const handleDeleteFriendRequest = async otherPartyId => {
  try {
    // Send a DELETE request to the backend with otherPartyId as a query parameter
    const response = await axiosPrivate.delete("/api/friend-request", {
      params: { otherPartyId }, // Pass the other party's user ID as a query parameter
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.error("Unexpected response status:", response.status);
    }
  } catch (error) {
    console.error("Error deleting friend request:", error);
    // Optionally, handle the error further based on your requirements
  }
};

export const handleAcceptFriendRequest = async (requester_user_id: number, recipient_user_id: number) => {
  try {
    const response = await axiosPrivate.post("/api/friend-request", { requester_user_id: requester_user_id, recipient_user_id: recipient_user_id });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error adding contact:", error);
  }
};

export const handleBlockRequest = async (requester_user_id: number) => {
  try {
    const response = await axiosPrivate.get("/api/block-request", { params: { r: requester_user_id } });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error("Error adding contact:", error);
  }
};
