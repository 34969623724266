import { axiosPrivate } from "../configuration/axiosConfig.ts";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const useLoginHandlers = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleLogin = async (username: string, password: string) => {
    try {
      const response = await axiosPrivate.post("/api/login", { username, password });
      if (response.status === 200) {
        navigate("/chat");
      }
    } catch (err) {
      setError("Login failed. Please check your credentials.");
    }
  };

  const handleRegister = async (username: string, email: string, password: string, confirmPassword: string) => {
    setError("");

    // Check if passwords match before making the API call
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return; // Stop the function if passwords are not the same
    }

    try {
      const response = await axiosPrivate.post("/api/register", { username, email, password });
      if (response.status === 201) {
        setShowSuccessModal(true); // Show success modal
        setTimeout(() => {
          navigate(`/login`);
        }, 2000); // Wait for 2 seconds before navigating
      }
    } catch (err) {
      setError("Registration failed. Please check your details.");
    }
  };

  const handleForgotPassword = async (email: string) => {
    try {
      const response = await axiosPrivate.post("/api/forgot-password", { email });
      if (response.status === 200) {
        alert("A reset link has been sent to your email.");
      } else {
        setError("Failed to send reset link. Please try again.");
      }
    } catch (err) {
      setError("Failed to send reset link. Please check your email and try again.");
    }
  };

  return { handleLogin, handleRegister, handleForgotPassword, showSuccessModal, error };
};

export default useLoginHandlers;
