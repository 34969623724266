import React from "react";
import "./UserInfoModal.css"; // Add necessary styles
import { FaXmark } from "react-icons/fa6";
import { useUser } from "../../ContextProviders/UserContext.tsx"; // Adjust import path

interface UserInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (description: string) => void;
}

const DescriptionModal: React.FC<UserInfoModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const { description, setDescription } = useUser();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(description || "");
    setDescription(""); // Clear description after submission
    onClose(); // Close modal after submission
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay-user">
      <div className="modal-content-user">
        <button className="modal-close-user" onClick={onClose}>
          <FaXmark />
        </button>
        <div className="update-description">Update Description</div>
        <form onSubmit={handleSubmit}>
          <textarea value={description || ""} onChange={e => setDescription(e.target.value)} placeholder="Enter your description" rows={4} />
          <button className="submitButton-user" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default DescriptionModal;
