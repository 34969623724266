// components/NotificationModal.tsx
import React, { useEffect, useState } from "react";
import { useNotification } from "../../ContextProviders/NotificationContext.tsx";
import "./NotificationModal.css";
import { FaXmark } from "react-icons/fa6";

const NotificationModal = () => {
  const { notification, hideNotification } = useNotification();
  const [countdown, setCountdown] = useState(5);
  const [isSlidingOut, setIsSlidingOut] = useState(false);

  useEffect(() => {
    if (notification) {
      setCountdown(5);
      setIsSlidingOut(false);

      const interval = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(interval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      const timer = setTimeout(() => {
        setIsSlidingOut(true);
        const hideTimer = setTimeout(() => {
          hideNotification();
        }, 500);

        return () => clearTimeout(hideTimer);
      }, 6000);

      return () => {
        clearInterval(interval);
        clearTimeout(timer);
      };
    }
  }, [notification, hideNotification]);

  if (!notification) return null;

  // Set color based on notification type
  const lineColor = notification.type === "success" ? "green" : notification.type === "error" ? "red" : "yellow";

  return (
    <div className={`notification-modal-container ${isSlidingOut ? "slide-out" : "slide-in"}`}>
      <div className="notification-modal-content">
        <div className="progress-line" style={{ width: `${(countdown / 5) * 100}%`, backgroundColor: lineColor }}></div>
        <div className="notification-message">{notification.message}</div>
        <button className="close-btn" onClick={hideNotification}>
          <FaXmark className="close-mark" />
        </button>
      </div>
    </div>
  );
};

export default NotificationModal;
